import { Vue, Component, Watch, Ref, Prop } from 'vue-property-decorator';
import { Action, State as StateClass } from 'vuex-class';
import { MetaInfo } from 'vue-meta';
import { ADD_TOAST_MESSAGE as addToastMessage } from 'vuex-toast';
import { ValidationObserver } from 'vee-validate';
import { State } from '@/store/models';
import { titleTemplate } from '@/helpers/meta/title-template';
import FormInput from '@/components/common/form-elements/FormInput.vue';
import * as MUTATIONS from '@/store/constants';

@Component({
  components: {
    ValidationObserver,
    FormInput,
  },
})

export default class Reset extends Vue {
  @Prop() isAuthPage!: boolean;

  metaInfo(): MetaInfo {
    return {
      title: titleTemplate(this.$t('meta.reset.title').toString()),
    };
  }

  email: string = '';

  @Action sendResetPassword!: Function;
  @Action(addToastMessage) addToastMessage!: Function;
  @Action openModal!: Function;

  @StateClass operations!: State['operations'];

  @Ref('form') readonly form!: InstanceType<typeof ValidationObserver>;

  @Watch('operations', { deep: true })
  onUserChanged(newAuth: State['operations'], oldAuth: State['operations']): void {
    if (newAuth
      && oldAuth
      && oldAuth.name
      && oldAuth.name === MUTATIONS.RESET_PASSWORD_PROCESSING
      && oldAuth.name !== newAuth.name) {
      // Notification
      this.addToastMessage({
        text: newAuth.name === MUTATIONS.RESET_PASSWORD_SUCCESS
          ? `${this.$t('auth.reset.emailSent')}. ${this.$t('auth.reset.pleaseCheckInbox')}.`
          : newAuth.error,
        type: newAuth.name === MUTATIONS.RESET_PASSWORD_SUCCESS ? 'success' : 'danger',
      });

      // Reset form to initial state, and prevent
      // showing validation errors ('... is required')
      if (newAuth.name === MUTATIONS.RESET_PASSWORD_SUCCESS) {
        this.email = '';
        this.$nextTick((): void => {
          this.form.reset();
        });
      }
    }
  }
}
