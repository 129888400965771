import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Action, State as StateClass } from 'vuex-class';
import { MetaInfo } from 'vue-meta';
import { ADD_TOAST_MESSAGE as addToastMessage } from 'vuex-toast';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { State } from '@/store/models';
import Loader from '@/components/common/loader/Loader.vue';
import { titleTemplate } from '@/helpers/meta/title-template';
import FormInput from '@/components/common/form-elements/FormInput.vue';
import { firebase } from '@/firebase';
import to from 'await-to-js';

@Component({
  components: {
    Loader,
    ValidationObserver,
    ValidationProvider,
    FormInput,
  },
})

export default class AuthVerification extends Vue {
  @Prop() isAuthPage!: boolean;
  metaInfo(): MetaInfo {
    return {
      title: titleTemplate(this.$t('meta.verification.title').toString()),
    };
  }

  password = '';
  confirmation = '';
  email = '';
  fetchError = false;

  @Action emailVerification!: Function;
  @Action resetPassword!: Function;
  @Action recoverEmail!: Function;
  @Action revertSecondFactor!: Function;
  @Action checkActionCode!: Function;
  @Action openModal!: Function;
  @Action(addToastMessage) addToastMessage!: Function;
  @StateClass operations!: State['operations'];

  @Watch('actionCode')
  onNewOobCode(newOobCode: string, oldOobCode: string): void {
    if (!oldOobCode && newOobCode && (
      this.mode === 'verifyEmail' ||
      this.mode === 'recoverEmail' ||
      this.mode === 'revertSecondFactorAddition'
    )) {
      this.triggerAction();
    }

    if (!oldOobCode && newOobCode && this.mode === 'resetPassword' && this.setup) {
      this.checkActionCode({ actionCode: newOobCode });
    }
  }

  @Watch('mode')
  onNewMode(newMode: string): void {
    if (!newMode) {
      this.$router.replace('/landing').catch((): void => {});
    }
  }

  @Watch('error')
  onErrorChange(newIsError: boolean, oldIsError: boolean): void {
    if (!oldIsError && newIsError && !this.fetchError) {
      this.addToastMessage({
        text: this.operations.error,
        type: 'danger',
      });
    }
  }

  mounted(): void {
    if (!this.actionCode || !this.mode) {
      this.$router.replace('/landing').catch((): void => {});
    }

    if (this.actionCode && (this.mode === 'verifyEmail' || this.mode === 'verifyAndChangeEmail' || this.mode === 'recoverEmail')) {
      this.triggerAction();
    }

    if (this.actionCode && this.mode === 'resetPassword' && this.setup) {
      this.checkActionCode({ actionCode: this.actionCode });
    }
  }

  async triggerEmailVerification(): Promise<void> {
    let email: string;
    const [actionCodeInfoError, actionCodeInfo] = await to(firebase.auth().checkActionCode(this.actionCode));
    if (actionCodeInfoError) {
      this.fetchError = true;
      this.addToastMessage({
        text: 'Error with email update',
        type: 'danger',
      });
      return;
    }
    this.fetchError = false;

    email = (actionCodeInfo as any).data.email;
    if (email && typeof email === 'string' && email.includes(' ')) {
      email = email.replace(' ', '+');
    }
    this.emailVerification({ actionCode: this.actionCode, mode: this.mode, email });
  }

  get expiredError(): boolean {
    return this.operations.error === 'The action code has expired.';
  }

  get actionCode(): string {
    return this.$route.query.oobCode as string;
  }

  get mode(): string {
    return this.$route.query.mode as string;
  }

  get setup(): string | undefined {
    return this.$route.query.setup as string | undefined;
  }

  triggerAction(): void {
    switch (this.mode) {
      case 'verifyEmail':
        this.emailVerification({ actionCode: this.actionCode });
        break;
      case 'verifyAndChangeEmail':
        this.triggerEmailVerification();
        break;
      case 'resetPassword':
        this.resetPassword({ actionCode: this.actionCode, newPassword: this.password });
        break;
      case 'recoverEmail':
        this.recoverEmail({ actionCode: this.actionCode });
        break;
      case 'revertSecondFactorAddition':
        this.revertSecondFactor({ actionCode: this.actionCode });
        break;
      default:
        break;
    }
  }

  get loading(): boolean {
    return this.operations.name === 'EMAIL_VERIFICATION_PROCESSING' ||
           this.operations.name === 'CONFIRM_PASSWORD_RESET_PROCESSING' ||
           this.operations.name === 'RECOVER_EMAIL_PROCESSING' ||
           this.operations.name === 'REVERT_SECOND_FACTOR_PROCESSING' ||
           this.operations.name === 'CHECK_ACTION_CODE_PROCESSING';
  }

  get error(): boolean {
    return this.operations.name === 'EMAIL_VERIFICATION_ERROR' ||
           this.operations.name === 'CONFIRM_PASSWORD_RESET_ERROR' ||
           this.operations.name === 'RECOVER_EMAIL_ERROR' ||
           this.operations.name === 'REVERT_SECOND_FACTOR_ERROR' ||
           this.operations.name === 'CHECK_ACTION_CODE_ERROR' ||
           this.fetchError;
  }

  get success(): boolean {
    return this.operations.name === 'EMAIL_VERIFICATION_SUCCESS' ||
           this.operations.name === 'CONFIRM_PASSWORD_RESET_SUCCESS' ||
           this.operations.name === 'RECOVER_EMAIL_SUCCESS' ||
           this.operations.name === 'REVERT_SECOND_FACTOR_SUCCESS';
  }

  get title(): string {
    switch (this.mode) {
      case 'verifyEmail':
        return 'emailVerification';
      case 'resetPassword':
        return this.setup && this.setup === 'true' ? 'claimPasswoord' : 'resetPassword';
      case 'recoverEmail':
        return 'changeEmail';
      case 'revertSecondFactorAddition':
        return 'secondFactor';
      default:
        return '';
    }
  }

  submitPassword(): void {
    if (this.password.length < 10) {
      this.addToastMessage({
        text: this.$t('auth.verification.passwordLenght'),
        type: 'danger',
      });
    } else {
      this.triggerAction();
    }
  }
}
