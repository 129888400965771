import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class AlertBox extends Vue {
  @Prop() errorMessage!: any;
  @Prop() loggedOutByInactivity: boolean | undefined;
  @Prop() isSuccess: boolean | undefined;

  /**
   * If we have an error.
   */
  get hasError(): boolean {
    return this.errorMessage !== null;
  }

  /**
   * Error message.
   */
  get error(): string {
    return this.errorMessage || '';
  }
}
